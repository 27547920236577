/* App.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Segoe UI', Verdana, sans-serif;
  font-size: 14px;
}

.hover-card {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  width: 250px;
  box-shadow: 0 14px 26px rgba(0,0,0,0.04);
  transition: all 0.3s ease-out;
}

.hover-card:hover::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 250;
  height: 100%;
  background: #dce9ff7a;
  z-index: 1;
  animation: hover-fill 1s infinite;
}

.hover-card:hover {
  /* transform: scale(1.05);
  transition: transform 0.3s ease-in-out; */
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0,0,0,0.11),
  0 24px 46px #dce9ff7a;
}

.hover-card:hover .MuiCard-root {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.8);
}

.hover-card:hover .overlay {
  transform: scale(4) translateZ(0);
}

.card:hover .circle {
  border-color: #f1f7ff;
  background: #DCE9FF;
}

.card:hover .circle:after {
  background: #f1f7ff;
}

/* 
.circle {
  width: 131px;
  height: 131px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #DCE9FF;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
}

.circle:after {
  content: "";
  width: 118px;
  height: 118px;
  display: block;
  position: absolute;
  background: #DCE9FF;
  border-radius: 50%;
  top: 7px;
  left: 7px;
  transition: opacity 0.3s ease-out;
} */

.overlay {
  width: 250px;
  position: absolute;
  height: 250px;
  border-radius: 50%;
  background: #f3f3f3;
  top: 120px;
  left: 250px;
  z-index: 0;
  transition: transform 0.3s ease-out;
}

@keyframes hover-fill {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

.mb-2 {
  margin-bottom: 12px;
}

.container {
  margin: 0 auto;
  min-height: 100vh;
  background-color: #d5d8df;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.form-container {
  padding: 16px;
  width: 350px;
  margin: 0 auto;
  margin-top: 50px;
}

.form-control {
  margin-bottom: 16px;
}

.form-control label {
  display: block;
  margin-bottom: 8px;
}

.form-control input[type="text"],
.form-control input[type="password"],
.form-control input[type="email"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.form-switch {
  margin-top: 16px;
  font-size: 15px;
}

.form-switch a {
  color: #007bff;
  text-decoration: none;
}

.form-switch a:hover {
  text-decoration: underline;
}

.form-container.signup-form {
  padding: 16px;
  width: 350px;
  margin-left: 16px;
}

.form-container.signup-form .form-header,
.form-container.login-form .form-header {
  margin-bottom: 16px;
  text-align: center;
}

.css-6uzb1x {
  /* padding-left: 44px !important;
  padding-top: 24px !important; */
  width: 83vw !important;
  margin-left: 10px !important;
}

.css-rqglhn-MuiTable-root {
  background-color: #daedf19e;
}

.col-md-3 {
  flex: 0 0 15%;
  max-width: 15%;
}

.col-md-9 {
  flex: 0 0 85%;
  max-width: 85%;
  background-color: #d5d8df;
}


.col-md-12 {
  flex: 0 0 100%;
  max-width: 100%;
}


.menu-button {
  position: absolute !important;
  top: 4px !important;
  left: 20px !important;
  z-index: 1000 !important;
  color: #fff !important;
}


.sidebar {
  background-image: linear-gradient(#6a11cb 0%, #3b25fc 100%);
  width: 224px;
  font-size: 13px;
  transition: transform 0.3s ease-in-out;
}

.sidebar-column {
  display: flex;
  flex-direction: column;
}

.sidebar-item {
  color: #fff;
  padding: 10px;
}

.sidebar-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.sidebar-item .nav-link {
  color: #fff;
  text-decoration: none;
  padding: 6px;
  border-radius: 4px;
  transition: background-color 0.3s;
  font-size: 13px;
}


.sidebar-hidden {
  transform: translateX(-100%);
  display: none;
}


.sidebar.visible {
  transform: translateX(0);
  display: block;
}

.home-container {
  margin-top: 20px;
  text-align: center;
}

.not-found-container,
.unauthorized-container {
  width: 100%;
  max-width: 500px;
  margin: 10rem auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.not-found-heading,
.unauthorized-heading {
  font-size: 24px;
  margin-bottom: 10px;
}

.not-found-message,
.unauthorized-message {
  font-size: 18px;
}

.not-found-message a,
.unauthorized-message a {
  font-weight: 500;
  color: #000;
}

.not-found-message a:hover {
  text-decoration: none;
}

.input-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-right: 8px;
}

.input-wrapper .input-label {
  margin-right: 8px;
  flex: 0 0 35%;
  font-size: 12px !important;
  text-wrap: balance;
  color: #000 !important;
}

.input-wrapper .input-label-3 {
  margin-right: 8px;
  flex: 0 0 50%;
  font-size: 12px !important;
  text-wrap: balance;
  color: #000 !important;
}


.input-label-2 {
  margin-bottom: 5px;
  font-size: 12px !important;
  color: #000 !important;
}

.input-wrapper .input-field {
  flex: 0 0 58%;
  background: #fdfbc8;
  border-radius: 4px;
  font-size: 12px !important;
}

.input-field {
  background: #fdfbc8 !important;
  border-radius: 4px !important;
  font-size: 12px !important;
}

.input-field input,
.input-field div {
  font-size: 12px !important;
}

.input-wrapper .label-adjustment {
  flex: 0 0 40% !important;
}

.input-wrapper .field-adjustment {
  flex: 0 0 60% !important;
}

.input-wrapper .supplier-adjustment {
  flex: 0 0 20% !important;
}

.input-wrapper .store-adjustment {
  flex: 0 0 41% !important;
}

.input-wrapper .entryno-adjustment {
  flex: 0 0 30% !important;
  margin-left: 20px;
}

.input-wrapper .entryNo-adjustment input {
  color: red !important;
}

.table-header {
  background-color: #91cbff;
  text-wrap: nowrap;
}

.table-head {
  background-color: #d5d8df;
  text-wrap: nowrap;
}

.table-head tr th,
.table-head-2 tr th {
  font-size: 12px;
  text-wrap: nowrap;
}

.table-head-2 th{
  background-color: #dae4ed !important;
}

.purchase-data-table tr td,
.purchase-data-table tr td input {
  font-size: 10.5px;
}

.custom-datagrid {
  background-color: #fff !important;
}

.custom-datagrid .css-yrdy0g-MuiDataGrid-columnHeaderRow {
  background: #dae4ed !important;
}

.radio-buttons-wrapper {
  display: flex;
  align-items: center;
  /* gap: 16px; */
}

.pcs-input .MuiOutlinedInput-input:focus {
  outline: none;
}

.pcs-input .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}

.date-picker,
.date-picker div {
  height: 29px;
}


@media (max-width: 768px) {
  .form-container {
    width: 80%;
    margin-top: 20px;
  }

  .col-md-3,
  .col-md-9,
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .sidebar {
    width: 100%;
  }

  .menu-button {
    top: 10px;
    left: 10px;
  }
}

@media print {
  .print-container {
    display: block !important;
  }
}
