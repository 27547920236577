@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');

.navbar {
    background-image: linear-gradient(to right, #6a11cb 0%, #2575fc 100%);
    padding: 12px 0;
}

.navbar ul {
    list-style-type: none;
    padding: 0 110px 0px 50px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-wrap: nowrap;
    font-size: 14px;
}

.navbar ul.nav-item {
    margin-right: 10px;
}

.navbar ul li.nav-title {
    margin-right: auto;
}

.navbar ul li.nav-item a.nav-link,
.navbar ul li.nav-item.dropdown .dropdown-toggle {
    color: #fff;
    text-decoration: none;
    padding: 14px;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.navbar ul li a.nav-link:hover,
.navbar ul li.nav-item .nav-link:hover {
    background-color: #ffe60042;
}

.dropdown-content,
.sale-status-dropdown-content,
.transfer-report-dropdown-content,
.urgent-order-dropdown-content,
.godown-showroom-ledger {
  display: none;
  position: absolute;
  background-color: #e8e8e8;
  min-width: 200px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 100;
  border-radius: 4px;
  font-size: 12px;
}

.dropdown-content.open {
    display: block;
    margin-top: 10px;
}

.sale-status-dropdown-content.open {
  display: block;
  left: 200px;
  top: 144px;
}

.transfer-report-dropdown-content.open{
  display: block;
  left: 200px;
  top: 0px;
}

.urgent-order-dropdown-content.open{
  display: block;
  left: 200px;
  top: 37px;
}

.godown-showroom-ledger.open{
  display: block;
  left: 200px;
  top: 108px;
}

.dropdown-content a,
.dropdown-content li,
.sale-status-dropdown-content a,
.transfer-report-dropdown-content a,
.urgent-order-dropdown-content a,
.godown-showroom-ledger a {
  color: #000;
  padding: 10px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
}

.dropdown-content a:hover,
.dropdown-content .nav-item:hover,
.sale-status-dropdown-content li:hover,
.transfer-report-dropdown-content li:hover,
.urgent-order-dropdown-content li:hover,
.godown-showroom-ledger li:hover {
  background-color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}

.navbar ul li.dropdown .dropdown-toggle i {
    margin-left: 5px;
}


.hamburger-icon {
    display: none;
    cursor: pointer;
  }
  
  .bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px 0;
  }
  
  /* Make the navbar responsive */
  @media (max-width: 768px) {
    .navbar-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .navbar-links {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: -100%;
      width: 100%;
      background: #333;
      transition: all 0.5s;
    }
  
    .navbar-links.active {
      left: 0;
    }
  
    .hamburger-icon {
      display: block;
    }
  }

/* display: block;
    left: 262px;
    top: 145px; */